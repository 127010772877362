<template>
  <appHeader></appHeader>
  <router-view></router-view>
  <appFooter></appFooter>
</template>
<script>
import HeaderVue from "./common/Header";
import FooterVue from "./common/Footer";
export default {
  components : {
    "appHeader" : HeaderVue,
    "appFooter" : FooterVue,
  },
  data() {
    return {
      active: false
    }
  },
  methods: {
    leave: function (el, done){
      setTimeout(function (){
        this.active = false
      },3000)
    },
    enter: function (el, done) {

      this.active = true
    }
  }
}

</script>
<style>
/* Make clicks pass-through */
#nprogress {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;

}

#nprogress .bar {
  background: #3257A4;

  position: fixed;
  top: calc(50% - 2.5px);
  left: 0;
  width: 100%;
  height: 5px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 10px;
  height: 100%;
  box-shadow: 0 0 10px #3257A4, 0 0 5px #3257A4;
  opacity: .3;

}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: calc(50% - 70px);
  right: calc(50% - 100px);
}

#nprogress .spinner-icon {
  background-image: url("@/assets/logo.svg");
  background-repeat: no-repeat;
  height: 50px;
  width: 250px;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}


</style>
