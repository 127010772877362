import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: home
  },
  {
    path: '/anasayfa',
    name: 'Anasayfa ',
    component: home
  },
  {
    path: '/kurumsal',
    name: 'Kurumsal',
    component: () => import('../views/About'),
  },
  {
    path: '/sektorler',
    name: 'Sektörler',
    component: () => import('../views/Sectors'),
  },
  {
    path: '/urunler',
    name: 'Ürünler',
    component: () => import('../views/Products'),
  },
  {
    path: '/urun/:id',
    name: 'Ürün',
    component: () => import('../views/Detail'),
  },
  {
    path: '/iletisim',
    name: 'İletişim',
    component: () => import('../views/Contact'),
  },
  {
    path: '/yonet',
    name: 'Yönet',
    component: () => import('../views/Admin'),
    meta: {
      header: 'default'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/NotFound')
  }
  ,
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  }
]


const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0,
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})


router.beforeEach((to, from, next)=>{
  document.title = 'Aslantaş Yay | '+to.name
  next()
})
export default router
