<template>
<!--  style="background-image: url(assets/images/img13.jpg);"-->
  <footer id="colophon" class="site-footer footer-primary">
    <div class="overlay"></div>
    <div class="container">
      <div class="top-footer">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <aside class="widget widget_text">
              <div class="footer-logo">
                <router-link to="/">
                  <img src="@/assets/logow.svg" width="200" height="50" alt="logo">
                </router-link>
              </div>
              <div class="textwidget widget-text">
                {{ langcontent.foottext }}
              </div>
              <div class="social-links">
                <ul>
                  <li v-if="contact.facebook">
                    <a :href="contact.facebook" target="_blank">
                      <i class="fab fa-facebook-f" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li v-if="contact.twitter">
                    <a :href="contact.twitter" target="_blank">
                      <i class="fab fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li v-if="contact.youtube">
                    <a :href="contact.youtube" target="_blank">
                      <i class="fab fa-youtube" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li v-if="contact.instagram">
                    <a :href="contact.instagram" target="_blank">
                      <i class="fab fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
          <div class="col-lg-2 offset-lg-1 col-md-6">
            <aside class="widget">
              <h3 class="widget-title">{{ langcontent.products }}</h3>
              <ul>
                <li v-for="item in cats.slice().reverse()">
                  <router-link :to="'/urunler/'+item.seo">{{ item.title }}</router-link>
                </li>
              </ul>
            </aside>
          </div>
          <div class="col-lg-2 col-md-6">
            <aside class="widget">
              <h3 class="widget-title">{{ langcontent.sectors }}</h3>
              <ul>
                <li v-for="item in sectors.slice(0,6)">
                  <router-link :to="'/sektorler/'+item.seo">{{ item.title }}</router-link>
                </li>
              </ul>
            </aside>
          </div>
          <div class="col-lg-3 col-md-4">
            <aside class="widget contact-widget">
              <h3 class="widget-title">{{ langcontent.contact }}</h3>
              <div class="widget-wrap row">
                <div class="contact-detail-widget">
                  <ul>
                    <li>
                      <a :href="'tel:'+contact.tel">
                        <i aria-hidden="true" class="icon icon-phone1"></i>
                        {{ contact.tel }}
                      </a>
                    </li>
                    <li>
                      <a :href="'mailto:'+contact.mail">
                        <i aria-hidden="true" class="icon icon-envelope1"></i>
                        {{contact.mail }}
                      </a>
                    </li>
                    <li>
                      <router-link to="/iletisim">
                        <i aria-hidden="true" class="icon icon-map-marker1"></i>
                        {{ contact.addr }}
                      </router-link>

                    </li>
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
      <div class="bottom-footer">
        <div class="row">
          <div class="col-lg-8 offset-lg-4">
            <div class="bottom-footer-content row">
              <div class="copy-right col-7">{{ langcontent.copy }}</div>
              <div class="footer-menu text-right col-5">
                <ul>
                  <li>
                  <a target="_blank" href="//gifadwork.com">Design by Gif Adw.</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import axios from '../axios.js'
export default {
  data(){
    return{
      langcontent: [],
      cats: [],
      sectors: [],
      contact: [],
    }
  },
  created() {
    axios.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axios.get('cats')
        .then(response => {
          this.cats = response.data
        })
        .catch(error => {
          console.log(error);
        })
    axios.get('sectors')
        .then(response => {
          this.sectors = response.data
        })
        .catch(error => {
          console.log(error);
        })

    axios.get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  }
}
</script>

<style scoped>
.mb-1{
  color: white;
}
</style>