<template>
  <main id="content" class="site-main">
    <section class="video-wrapper">
      <video autoplay loop muted playsinline webkit-playsinline>
        <source src="@/assets/render.mp4" type="video/mp4">
        <source src="@/assets/render.webm" type="video/webm">
      </video>
    </section>
    <section class="home-about">
      <div class="container">
        <div class="about-inner">
          <div class="row g-0">
            <div class="col-md-6" data-aos="fade-right" data-aos-duration="1000">
              <div :style="'background-image: url('+corporate.img+');'"
                   class="about-video-banner d-flex align-items-center justify-content-center">
              </div>
            </div>
            <div class="col-md-6" data-aos="fade-left" data-aos-duration="1000">
              <div class="progress-content">
                <div class="section-head-outer">
                  <div class="section-head">
                    <div class="title-divider"></div>
                    <h2 class="section-title">{{ corporate.title }}</h2>
                  </div>
                </div>
                <p>
                  {{ corporate.desc.slice(0, 149) + '...' }}
                  <br>
                  <router-link class="button-round mt-4" to="/kurumsal">{{ corporate.title }}</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home-about bg-light-grey">
      <div class="container">
        <div class="about-inner">
          <div class="row g-0">
            <div class="col-md-12">
              <div class="text-center mb-5" data-aos="fade-up" data-aos-duration="1000">
                <h2 class="section-title d-block w-100">{{ video.title }}</h2>
              </div>
              <div  data-aos="fade-up" data-aos-duration="1000" :style="'background-image: url('+video.img+');max-width:calc(100vw - 30px);'"
                   class="about-video-banner d-flex align-items-center justify-content-center">
                <div class="video-button" @click="toggler = !toggler">
                  <a id="video-container" data-video-id="IUN664s7N-c">
                    <i aria-hidden="true" class="icon icon-play1"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <FsLightbox
        :sources="[video.link]"
        :toggler="toggler"
        type="youtube"
    />
    <section class="home-service">
      <div class="container">
        <div class="section-head text-center">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="title-divider"></div>
              <h2 class="section-title"  data-aos="fade-up" data-aos-duration="1000">{{ langcontent.products }}</h2>
            </div>
          </div>
        </div>
        <div class="service-inner">
          <div class="row g-0 align-items-center">
            <div class="col-sm-12 col-md-5 col-lg-4 text-right">
                <div v-for="item in cats.slice(0,3)" class="service-icon-box">
                  <div class="service-icon-content"  data-aos="fade-left" data-aos-duration="1000">
                    <h4>
                      <router-link :to="'/urun/'+item.seo">{{ item.title }}</router-link>
                    </h4>
                  </div>
                  <div class="service-icon">
                    <img :src="item.gif" alt="">
                    <div class="static">
                      <img :src="item.png" alt="">
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-sm-6 offset-sm-3 offset-md-0 col-md-2 col-lg-4 text-center"  data-aos="fade-up" data-aos-duration="500">
              <figure class="service-image">
                <img alt="aslantaş yay" src="@/assets/p.jpg">
              </figure>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-4 text-left">
              <div v-for="item in cats.slice(3,6)" class="service-icon-box">
                <div class="service-icon-content"  data-aos="fade-right" data-aos-duration="1000">
                  <h4>
                    <router-link :to="'/urun/'+item.seo">{{ item.title }}</router-link>
                  </h4>
                </div>
                <div class="service-icon">
                  <img :src="item.gif" alt="">
                  <div class="static">
                    <img :src="item.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<script>
import FsLightbox from "fslightbox-vue/v3";
import axios from "@/axios";

export default {
  components: {FsLightbox},
  data() {
    return {
      corporate: [],
      video: [],
      cats: [],
      langcontent: [],
      toggler: false
    }
  },
  created() {
    axios.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
          document.title = 'Aslantaş Yay | ' + this.langcontent.home;
        })
        .catch(error => {
          console.log(error);
        })
    axios.get('corporate')
        .then(response => {
          this.corporate = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axios.get('video')
        .then(response => {
          this.video = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axios.get('cats')
        .then(response => {
          this.cats = response.data
        })
        .catch(error => {
          console.log(error);
        })
  }
}
</script>
<style scoped>
.video-wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.video-wrapper video {
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  top: 0;
  left: 0;
  background: #000;
}
.service-icon{
  position: relative;
  overflow: hidden;
}
.service-icon img{
  max-width: 80px;
  /*filter: hue-rotate(106deg) saturate(.8) brightness(.65) contrast(250%);*/
  position: relative;
}
.service-icon .static {
  position: absolute;
  width: 100%;
  height: 100%;
padding:1px ;
  left: 0;
  top: 0;
  background: #fff;
}

.service-icon-box:hover .static{
  opacity: 0;

}
</style>
