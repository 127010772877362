<template>
  <header id="masthead" class="site-header site-header-transparent">
    <!-- header html start -->
    <div class="top-header">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 d-none d-lg-block">
            <div class="header-contact-info">
              <ul>
                <li>
                  <a :href="'tel:'+contact.tel">
                    <i class="fas fa-phone-alt"></i>
                    {{ contact.tel }}
                  </a>
                </li>
                <li>
                  <a :href="'mailto:'+contact.mail">
                    <i class="fas fa-envelope"></i>
                    {{contact.mail }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 d-flex justify-content-lg-end justify-content-between">
            <div class="header-social social-links">
              <ul>
                <template v-for="item in langs">
                  <li style="cursor: pointer"   v-if="!(getLang == item.kisaltma)">
                    <a class="nav-link" @click="langChange(item.kisaltma)" >{{ item.ad }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-header">
      <div class="container">
        <div class="hgroup-wrap d-flex justify-content-between align-items-center">
          <div class="site-identity">
            <h1 class="site-title">
              <router-link to="/">
                <img src="@/assets/logo.svg" width="200" height="50" alt="logo">
              </router-link>
            </h1>
          </div>
          <div class="main-navigation">
            <nav id="navigation" class="navigation d-none d-lg-inline-block">
              <ul>
                <li>
                 <router-link to="/">{{ langcontent.home }}</router-link>
                </li>
                <li>
                  <router-link to="/kurumsal">{{ langcontent.corporate }}</router-link>
                </li>
                <li>
                  <router-link to="/urunler">{{ langcontent.products }}</router-link>
                </li>
                <li>
                  <router-link to="/sektorler">{{ langcontent.sectors }}</router-link>
                </li>
                <li>
                  <router-link to="/iletisim">{{ langcontent.contact }}</router-link>
                </li>
              </ul>
            </nav>
            <span class="d-block d-lg-none" @click="openM = !openM">
              <i class="fa fa-bars fw-bold fs-5 text-black" aria-hidden="true" data-v-8084e054=""></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div :class="['mobMenu', (openM === true ? 'activeMob' : '')]">
    <div class="ccc">
        <span class="d-block d-lg-none" @click="openM = false" v-if="openM">
              <i class="fa fa-times fw-bold text-black" aria-hidden="true" data-v-8084e054=""></i>
        </span>
      <ul  @click="openM = false">
        <li>
          <router-link to="/">{{ langcontent.home }}</router-link>
        </li>
        <li>
          <router-link to="/kurumsal">{{ langcontent.corporate }}</router-link>
        </li>
        <li>
          <router-link to="/urunler">{{ langcontent.products }}</router-link>
        </li>
        <li>
          <router-link to="/sektorler">{{ langcontent.sectors }}</router-link>
        </li>
        <li>
          <router-link to="/iletisim">{{ langcontent.contact }}</router-link>
        </li>
      </ul>
    </div>
  </div>

</template>
<script>
import axios from '../axios.js'
export default {
  data(){
    return{
      langs: [],
      langcontent: [],
      openM: false,
      contact: [],
      getLang : 'tr'
    }
  },
  created() {
    axios.get('langfix')
        .then(response => {
          this.langcontent = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axios.get('diller')
        .then(response => {
          let data = response.data
          // console.log(data)
          for (let key in data) {
            this.langs.push(data[key])
          }
        })


    axios.get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })

    localStorage.lang = localStorage.getItem('lang')
    this.getLang = localStorage.getItem('lang')
  },
  methods: {
    langChange(e) {
      const firstPath = window.location.pathname.split('/')[1];
      // || firstPath == 'urun'
      if (firstPath == 'urun' ) {
        localStorage.lang = e
        document.location.href = "/";
      } else {
        localStorage.lang = e
        location.reload(true);
      }
    }
  }
}
</script>
<style scoped>
.router-link-active{
  transition: all .3s ease;
  color: #3257A4!important;
}
.mobMenu{
  position: fixed;
  overflow: hidden;
  left: -100%;
  bottom: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 99;
  transition: all ease .3s;
}
.ccc{
  position: relative;
}
.mobMenu.activeMob{
  overflow: hidden;

  left: 0;
}
.mobMenu{
  font-size: 40px;
  text-decoration: none;
  color: black;
}
.mobMenu span{
  position: absolute;
  left: 85vw;
}
.mobMenu ul{
  position: absolute;
  top: 20px;
  left: 20px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
  font-weight: bold;
  color: black;
}
.mobMenu ul li a{
  color: black;
}
.mobMenu ul li a.router-link-active,
.mobMenu ul li a.router-link-exact-active {
  color: #3257A4;
}

/*background: #383838;*/
/*border-radius: 0 0 30px 30px;*/
</style>